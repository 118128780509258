import React from "react";
import ProjectCard from "../projectCard";
import Typography from "../typography";
import useProjects from "../../hooks/useProjects";
import { Project } from "../../models/project";
import * as s from "./projectList.module.scss";

type ProjectListProps = {
  projectSlug: string;
};

const ProjectList = ({ projectSlug }: ProjectListProps): JSX.Element => {
  const projects: Project[] = useProjects();
  const filteredProjects: Project[] = projects.filter(
    (project: Project) => project.node.slug !== projectSlug
  );

  return (
    <div className={s.projectList}>
      <Typography variant="h2" gutterBottom>
        Check these out too.
      </Typography>
      <div className={s.projectsGrid}>
        {filteredProjects.map((project, i) => {
          return (
            <ProjectCard
              slug={project.node.slug}
              thumbnail={project.node.projectThumbnail}
              name={project.node.projectName}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ProjectList;
