import { graphql, useStaticQuery } from "gatsby";

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query ProjectsQuery {
      allContentfulProject {
        edges {
          node {
            slug
            projectThumbnail {
              gatsbyImageData(aspectRatio: 1, placeholder: BLURRED)
            }
            projectName
            projectDescription {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  `);
  return data.allContentfulProject.edges;
};

export default useProjects;
