import React from "react";
import * as s from "./videoPlayer.module.scss";

type VideoPlayerProps = {
  src: string;
  className?: string | undefined;
  controls?: boolean;
  loop?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  videoWidth?: number;
  videoHeight?: number;
};

const VideoPlayer = ({
  className,
  src,
  controls,
  loop,
  autoPlay,
  muted,
  videoHeight,
  videoWidth,
}: VideoPlayerProps): JSX.Element => {
  return (
    <div className={[s.videoPlayer, className].join(" ")}>
      <video
        {...(loop && { loop })}
        {...(autoPlay && { autoPlay })}
        {...(muted && { muted })}
        {...(controls && { controls })}
        width={videoWidth ? `${videoWidth}%` : "auto"}
        height={videoHeight ? `${videoHeight}%` : "auto"}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
