import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Seo from "../../components/seo";
import Layout from "../../components/layout";
import ContentWrapper from "../../components/contentWrapper";
import Typography from "../../components/typography";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { MediaElem, SingleProject } from "../../models/project";
import ProjectList from "../../components/projectList";
import VideoPlayer from "../../components/videoPlayer";
import * as s from "./styles.module.scss";

const ProjectTemplate = ({
  data: { contentfulProject },
}: SingleProject): JSX.Element => {
  const displayMedia = (el: MediaElem) => {
    switch (el.file.contentType) {
      case "image/jpeg":
        return (
          <GatsbyImage
            className={s.projectTemplate__image}
            key={el.file.url}
            image={getImage(el!.gatsbyImageData!) as IGatsbyImageData}
            alt=""
          />
        );
      case "video/mp4":
        return (
          <VideoPlayer
            className={s.projectTemplate__video}
            videoHeight={100}
            videoWidth={100}
            src={el.file.url}
            controls
            muted
            autoPlay
            key={el.file.url}
          />
        );
    }
  };

  return (
    <Layout>
      <Seo
        title={contentfulProject.projectName}
        description={contentfulProject.metaDescription}
      />
      <ContentWrapper>
        <div className={s.projectTemplate}>
          <Typography variant="h4">{contentfulProject.dates}</Typography>
          <Typography variant="h1" align="left">
            {contentfulProject.projectName}
          </Typography>
          <div className={s.projectTemplate__disciplines}>
            {contentfulProject.disciplines.map((discipline, i) => {
              return (
                <Typography variant="small" key={i}>
                  {discipline}{" "}
                </Typography>
              );
            })}
          </div>
          <div className={s.projectTemplate__description}>
            <MDXRenderer>
              {contentfulProject.projectDescription.childMdx.body}
            </MDXRenderer>
          </div>
          <div className={s.projectTemplate__media}>
            {contentfulProject.projectMedia.map((el, i) => displayMedia(el))}
          </div>
        </div>
        <hr />
        <ProjectList projectSlug={contentfulProject.slug} />
      </ContentWrapper>
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query ProjectQuery($id: String) {
    contentfulProject(id: { eq: $id }) {
      dates
      slug
      metaDescription
      disciplines
      projectName
      projectDescription {
        childMdx {
          body
        }
      }
      projectMedia {
        file {
          contentType
          url
        }
        gatsbyImageData
      }
    }
  }
`;
