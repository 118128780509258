import React from "react";
import { navigate } from "gatsby-link";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import Typography from "../typography";
import * as s from "./projectCard.module.scss";

type ProjectCardProps = {
  slug: string;
  name: string;
  thumbnail: IGatsbyImageData;
};

const ProjectCard = ({
  slug,
  name,
  thumbnail,
}: ProjectCardProps): JSX.Element => {
  return (
    <div onClick={() => navigate(`/work/${slug}`)} className={s.projectCard}>
      <GatsbyImage
        objectFit="cover"
        className={s.projectCard__thumbnail}
        image={getImage(thumbnail) as IGatsbyImageData}
        alt={name}
      />
      <div className={s.projectCard__meta}>
        <Typography variant="h4">{name}</Typography>
      </div>
    </div>
  );
};

export default ProjectCard;
